<template>
  <div>
    <div v-if="user">
      <accordion headline="ACCOUNT INFORMATION" :active="true">
        <account-information :user="user" />
      </accordion>

      <accordion headline="ACCOUNT VERIFICATION" :active="true">
        <div class="box box--md box--white">
          <table class="table table--list has-bordered three-items">
            <tbody>
              <tr>
                <td>
                  <div class="statistic">
                    <p class="statistic__title text-warm">Email</p>
                    <h2 class="headline--md mt-4">{{ user.email }}</h2>
                  </div>
                </td>
                <td>
                  <div class="statistic">
                    <p class="statistic__title text-warm">Status</p>
                    <h2
                      class="headline--md mt-4 text-uppercase text-bold"
                      :class="user.emailVerification === 2 ? 'text-success' : 'text-error'"
                    >
                      {{ user.emailVerification | format_verified_text }}
                    </h2>
                  </div>
                </td>
                <td />
              </tr>

              <tr>
                <td>
                  <div class="statistic">
                    <p class="statistic__title text-warm">Mobile number</p>
                    <h2 class="headline--md text-uppercase mt-4">{{ user.countryCode }} {{ user.phone }}</h2>
                  </div>
                </td>
                <td>
                  <div class="statistic">
                    <p class="statistic__title text-warm">Status</p>
                    <h2
                      class="headline--md mt-4 text-uppercase text-bold"
                      :class="user.phoneVerification === 2 ? 'text-success' : 'text-error'"
                    >
                      {{ user.phoneVerification | format_verified_text }}
                    </h2>
                  </div>
                </td>
                <td />
              </tr>

              <tr v-if="userDocumentCodeObj">
                <td>
                  <div class="statistic">
                    <p class="statistic__title text-warm">Document Code</p>
                    <h2 class="headline--md mt-4">{{ userDocumentCodeObj.documentCode | format_document_code }}</h2>
                  </div>
                </td>
                <td>
                  <button v-show="userIdentifyImages" class="btn btn--primary" @click="onOpenSliderImage(0)">
                    Check ID
                  </button>
                </td>
                <td />
              </tr>

              <tr>
                <td>
                  <div class="statistic">
                    <p class="statistic__title text-warm">Identity document</p>
                    <h2 class="headline--md text-uppercase mt-4">ID card (KTP)</h2>
                  </div>

                  <div v-if="user.identityDocument">
                    <div v-if="userIdentifyImages" class="mt-12 images">
                      <img
                        v-for="(image, index) in userIdentifyImages"
                        :key="index"
                        :src="image"
                        @click="onOpenSliderImage(index)"
                        width="110"
                        class="image"
                        :class="index > 1 && 'mt-12'"
                        alt="image indentify"
                      />
                    </div>

                    <img v-else src="@/assets/img_spinner.gif" width="80" alt="spinner" />
                  </div>

                  <p v-else class="mt-12 text-error">No image available</p>
                </td>
                <td>
                  <div class="statistic">
                    <p class="statistic__title text-warm">Status</p>
                    <h2
                      class="headline--md mt-4 text-uppercase text-bold"
                      :class="user.documentVerification === 2 ? 'text-success' : 'text-error'"
                    >
                      {{ user.documentVerification | format_verified_text }}
                    </h2>
                  </div>
                </td>
                <td />
              </tr>

              <tr v-if="$auth('Page', currentUser, $accessRule.KycApprove).canView()">
                <td colspan="3" class="text-center">
                  <template v-if="!accountVerified && user.documentVerification === 1">
                    <button class="btn btn--red-bordered" @click="onRejectUser(user.id)">Reject</button>
                    <button class="btn btn--primary" @click="approveUser(user.id)">Approved</button>
                  </template>
                  <button
                    v-if="$auth('Page', currentUser, $accessRule.ApproveJoinStaking).canView()"
                    class="btn"
                    @click="isEditUserModalOpen = true"
                  >
                    Edit Profile
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </accordion>
    </div>

    <p v-else>No data</p>

    <accordion v-if="userReferrer" headline="REFERRER INFORMATION" :active="true">
      <account-information :user="userReferrer.user">
        <td>
          <div class="statistic">
            <p class="statistic__title text-warm">Mobile number</p>
            <h2 class="headline--md mt-4">{{ userReferrer.user.countryCode }}{{ userReferrer.user.phone }}</h2>
          </div>
        </td>
      </account-information>
    </accordion>

    <accordion v-if="reportReferrer && !isKycReferralReward" headline="REFERRAL INFORMATION">
      <referral-information :reportReferrer="reportReferrer" :reportReferredUsers="localReferredUsers">
        <template v-slot:searchBox>
          <div class="d-flex">
            <h3 class="headline--md text-bold">
              List of users used {{ reportReferrer.referrer.fullName }} referral link
            </h3>

            <div class="search-box search-box--gray">
              <span class="search-box__icon">
                <toko-search-icon />
              </span>
              <input
                type="text"
                class="form-control search-box__input"
                placeholder="Search by email"
                v-model="searchText"
                @keydown.enter="onGetReferredUsers"
              />
            </div>
          </div>
        </template>

        <template v-slot:pagination>
          <pagination
            v-if="isNextPage(reportReferredUsers.total)"
            v-model="params.page"
            :page-count="getPageCount(reportReferredUsers.totalPages, reportReferredUsers.total)"
            :page-range="5"
            :click-handler="onGetReferredUsers"
          ></pagination>
        </template>
      </referral-information>
    </accordion>

    <accordion v-if="transactions" headline="TRANSACTIONS">
      <div class="box box--md box--white">
        <transactions v-if="transactions.data" :transactions="transactions.data" :isNewTab="true" />

        <div class="text-center mt-32">
          <p>{{ transactions.total | format_transaction_count_text }} in total</p>

          <pagination
            v-if="transactions.total > transactionParams.perPage"
            v-model="transactionParams.page"
            :page-count="transactions.totalPages || Math.ceil(transactions.total / transactionParams.perPage)"
            :page-range="5"
            :click-handler="clickPagerTransactionCallback"
          ></pagination>
        </div>
      </div>
    </accordion>

    <modal v-if="isImageSliderOpen" modal-class="modal--fluid d-flex align-start" :open.sync="isImageSliderOpen">
      <image-slider ref="imageSlider" :currentIndex="currentIndex" :images="userIdentifyImages" class="modal__item">
      </image-slider>

      <document-check
        :existsUserDocuments="existsUserDocuments"
        :cardFormHeight="cardFormHeight"
        :code="userDocumentCodeObj && userDocumentCodeObj.documentCode"
        @save-document="onSaveDocument($event)"
        @on-check="onCheckUserIdentifyDocument($event)"
      >
      </document-check>
    </modal>

    <modal
      v-if="isEditUserModalOpen && user && $auth('Page', currentUser, $accessRule.ApproveJoinStaking).canView()"
      modal-class="modal modal--md"
      :open.sync="isEditUserModalOpen"
    >
      <edit-user-profile :profile="user" @success="isEditUserModalOpen = false" />
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import pagination from '@/mixins/pagination'
import handleReferrer from '@/mixins/handle-referrer'
const ImageSlider = () => import('@/components/ImageSlider')
const Modal = () => import('@/components/Modal')
const ReferralInformation = () => import('@/components/ReferralInformation')
const AccountInformation = () => import('@/components/AccountInformation')
const Accordion = () => import('@/components/Accordion')
const TokoSearchIcon = () => import('@/ui/toko-icons/TokoSearch')
const Transactions = () => import('@/components/Transactions')
const Pagination = () => import('@/components/Pagination')
const DocumentCheck = () => import('./components/DocumentCheck')
const EditUserProfile = () => import('./components/EditUserProfile')

export default {
  data() {
    return {
      cardFormHeight: 480,
      isImageSliderOpen: false,
      isEditUserModalOpen: false,
      transactionParams: {
        page: 1,
        perPage: 10
      }
    }
  },

  mixins: [pagination, handleReferrer],

  components: {
    Modal,
    ImageSlider,
    TokoSearchIcon,
    ReferralInformation,
    AccountInformation,
    Accordion,
    DocumentCheck,
    Transactions,
    Pagination,
    EditUserProfile
  },

  computed: {
    ...mapState('user', ['user', 'userIdentifyImages', 'userReferrer', 'existsUserDocuments', 'userDocumentCodeObj']),

    ...mapState('transaction', ['transactions']),

    accountVerified() {
      return this.user.documentVerification === 2
    }
  },

  methods: {
    ...mapActions('user', [
      'getUser',
      'approveUser',
      'rejectUser',
      'checkUserIdentifyDocument',
      'updateUserIdentifyDocument'
    ]),

    ...mapActions('transaction', ['getTransactions']),

    ...mapMutations('user', ['CLEAR_USER_DATA', 'CLEAR_EXISTS_USER_DOCUMENTS']),

    ...mapMutations('transaction', ['CLEAR_TRANSACTIONS_DATA']),

    onOpenSliderImage(target) {
      this['CLEAR_EXISTS_USER_DOCUMENTS']()
      this.isImageSliderOpen = true
      this.currentIndex = target
    },

    onResizeCardForm() {
      const imageSliderEl = this.$refs.imageSlider.$el
      if (!imageSliderEl) return

      const HEIGHT_SUBTRACT = 128
      const MIN_HEIGHT = 200
      this.cardFormHeight =
        imageSliderEl.offsetHeight < MIN_HEIGHT
          ? imageSliderEl.offsetHeight
          : imageSliderEl.offsetHeight - HEIGHT_SUBTRACT
    },

    onRejectUser(userId) {
      this.rejectUser(userId).then(() => {
        this.$router.push('/users')
      })
    },

    onCheckUserIdentifyDocument(code) {
      const dataRequest = {
        id: this.userDocumentCodeObj.id,
        code
      }

      this.checkUserIdentifyDocument(dataRequest).then(() => {
        this.onResizeCardForm()
      })
    },

    onSaveDocument(code) {
      const dataRequest = {
        id: this.userDocumentCodeObj.id,
        code
      }

      this.updateUserIdentifyDocument(dataRequest)
        .then(() => {
          this.isImageSliderOpen = false
        })
        .catch(() => {
          alert('Update fail, please try again!')
        })
    },

    getTransactionParams() {
      return {
        page: this.transactionParams.page,
        limit: this.transactionParams.perPage,
        userId: this.user.id
      }
    },

    clickPagerTransactionCallback() {
      this.getTransactions(this.getTransactionParams())
    }
  },

  created() {
    this['CLEAR_USER_DATA']()
    this['CLEAR_TRANSACTIONS_DATA']()
    this.getUser(this.$route.params.id).then(() => {
      this.getTransactions(this.getTransactionParams())
    })
  }
}
</script>
