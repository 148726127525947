import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      params: {
        page: 1,
        perPage: 10
      },
      searchText: ''
    }
  },

  computed: {
    ...mapState('referral', ['reportReferrer', 'reportReferredUsers', 'referrerData']),

    isKycReferralReward() {
      return (
        this.reportReferredUsers &&
        this.reportReferredUsers.users &&
        this.reportReferredUsers.users.length === 1 &&
        this.reportReferredUsers.users[0].user.id === parseInt(this.$route.params.id)
      )
    },

    localReferredUsers() {
      let recepients
      if (!this.reportReferredUsers) return
      recepients = this.reportReferredUsers.users.filter(item => item.user.id !== parseInt(this.$route.params.id))
      return {
        ...this.reportReferredUsers,
        users: recepients
      }
    }
  },

  methods: {
    ...mapActions('referral', ['getReportReferrer', 'getReportReferralRecipient']),

    ...mapMutations('referral', ['CLEAR_REPORT_REFERRER']),

    ...mapMutations('alert', ['CLEAR_ALERT']),

    getParamsRequest() {
      return {
        page: this.params.page,
        limit: this.params.perPage,
        email: this.searchText,
        host_id: parseInt(this.$route.params.id)
      }
    },

    onGetReferredUsers() {
      this.getReportReferralRecipient(this.getParamsRequest())
    }
  },

  created() {
    this['CLEAR_REPORT_REFERRER']()

    this.getReportReferrer(this.$route.params.id)
      .then(() => {
        this.getReportReferralRecipient(this.getParamsRequest())
      })
      .catch(() => {
        this['CLEAR_ALERT']()
      })
  }
}
